<template>
<page-wrapper id="overview">
  <v-data-table :loading="this.data === null"
                :headers="headers" :items="items"
                disable-pagination hide-default-footer>
    <template v-slot:top>
      <v-sheet tile height="54" class="d-flex align-center">
        <v-btn icon class="ma-2" @click="previousWeek">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon class="ma-2" @click="nextWeek">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-toolbar-title class="ma-2">
          Week of {{ startDay.format('dddd D MMMM') }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-checkbox v-model="onlyWorking" label="Only working">
        </v-checkbox>
      </v-sheet>
    </template>
    <template v-slot:item="{ item }">
      <tr>
        <td>
          <user-working-icon :working="item.days[0].working"
                             @click="toggleWeekWorking(item)">
          </user-working-icon>
        </td>
        <td>
          <router-link :to="{ name: 'user-calendar', params: { userSlug: slugify(item.name) } }"
                       class="text-decoration-none black--text hover-underline">
            {{ item.name }}
          </router-link>
        </td>
        <template v-if="item.days">
          <td v-for="(day, d) in item.days" :key="d"
              :class="{ off: !day.working, holiday: holidayDays[data.days[d]] !== undefined }">
            <router-link v-for="entry in day.entries" :key="`${item.userID}+${d}+${entry.job_number}`"
                         class="text-decoration-none"
                         :to="{ name: 'user-calendar',
                                params: { userSlug: slugify(item.name) },
                                query: { date: entry.day, entry_uid: entry.uid } }">
              <v-chip x-small
                      :style="{
                'background-color': entry.type === 'shoot' ? '#222' : jobColours[entry.job_number],
                'border-color': jobColours[entry.job_number]
              }"
                      text-color="#fff"
                      class="mr-1 hover-pointer" :class="{ [entry.type]: true }">
                {{ entry.type === 'leave' ? 'Leave' : entry.job_number }}
              </v-chip>
            </router-link>
          </td>
        </template>
        <template v-else>
          <td v-for="d in 7" :key="d"></td>
        </template>
      </tr>
    </template>
  </v-data-table>
</page-wrapper>
</template>

<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import PageWrapper from '../components/ui/PageWrapper'
import * as api from '../api'
import UserWorkingIcon from '../components/user/UserWorkingIcon'

export default {
  name: 'Overview',
  components: {
    UserWorkingIcon,
    PageWrapper
  },
  props: {
    startDate: {
      type: String,
      default: dayjs().day(1).startOf('day').format('YYYY-MM-DD')
    }
  },
  data() {
    return {
      data: null,
      onlyWorking: true
    }
  },
  watch: {
    startDay() {
      this.fetchDays()
    },
    hideOff() {

    }
  },
  computed: {
    ...mapState(['users', 'jobColours', 'holidayDays']),
    startDay() {
      return dayjs(this.startDate)
    },
    headers() {
      if (this.data) {
        const days = this.data.days.map((date) => {
          let text = dayjs(date).format('ddd D MMM')
          if (date in this.holidayDays) {
            text += ' (BH)'
          }
          return {
            text,
            value: date,
            sortable: false,
            width: '12.5%'
          }
        })
        return [{ text: 'Working', sortable: false }, { text: 'Name' }, ...days]
      }
      return []
    },
    items() {
      if (this.data && this.users) {
        return this.users
          .map((user) => {
            const { name, id, active } = user
            const days = this.data.users[id]
            const off = days[0].working
            return { name, userID: id, days, off, active }
          })
          .filter(user => ((this.onlyWorking && user.off) || !this.onlyWorking) && user.active)
      }
      return []
    }
  },
  methods: {
    async fetchDays() {
      this.data = await api.get('/overview', {
        start_date: this.startDay.format('YYYY-MM-DD'),
        end_date: this.startDay.add(6, 'days').endOf('day').format('YYYY-MM-DD')
      })
    },
    moveWeeks(weeks) {
      this.$router.push({
        params: { startDate: this.startDay.add(weeks, 'weeks').format('YYYY-MM-DD') }
      })
    },
    previousWeek() {
      this.moveWeeks(-1)
    },
    nextWeek() {
      this.moveWeeks(1)
    },
    async toggleWeekWorking(row) {
      await api.post('/user/working', {
        user_id: row.userID,
        working: !row.days[0].working,
        start_date: this.startDay.format('YYYY-MM-DD')
      })
      await this.fetchDays()
    }
  },
  beforeMount() {
    this.$store.dispatch('getJobColours')
  },
  async mounted() {
    await this.$store.dispatch('getUsers')
    await this.fetchDays()
    await this.$store.dispatch('getHolidayDays')
  }
}
</script>

<style lang="scss">
#overview {
  th:first-child {
    padding: 0 0 0 5px !important;
  }
  td:first-child {
    padding: 0 !important;
    background: #fff;
  }
  td:nth-child(2) {
    background: #fff;
  }
  .off {
    background: #eee;
    background: repeating-linear-gradient(
      -0deg,
      #fff,
      #fff 3px,
      #eee 3px,
      #eee 6px
    );
  }
  .holiday {
    background: #cfcfdf !important;
  }
  tr:hover {
    background: #f5f5f5 !important;
    td {
      background: #f5f5f5;
    }
  }

  span.v-chip {
    border-width: 2px;
    border-style: solid;
    padding: 0 10px;
    &.leave {
      color: #666 !important;
    }
    &.shoot{
      height: 36px;
    }
    &.leave {
      height: 30px;
    }
  }
}
</style>
